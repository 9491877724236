.link_school_code_form{
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    /* min-height: 75vh; */
    position: relative;
}

.link_school_code_form_btn{
    margin-top: 1rem;
    display: flex;
    justify-content: center;
    /* margin-bottom: 4rem; */
}

