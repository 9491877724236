body {
    margin: 0;
    padding: 0;
    font-family: "Rubik";
    background-color:#004EA3;
    --primary-color: #341744;
    --secondary-color: #FAAC68;
    --ternary-color: #263651;
    --border: #eee;
    --xxlg-font-size: 20px;
    --xlg-font-size: 18px;
    --lg-font-size: 16px;
    --md-font-size: 14px;
    --sm-font-size: 13px;
    --xsm-font-size: 12px;
    --xxsm: 11px;
}

input,
textarea,
button,
select,
a {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.flex {
    display: flex;
}

.flex-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.heading {
    font-size: 18px;
    font-weight: 600;
}

.btn-theme {
    border-radius: 100px;
    border: 0;
    min-width: 150px;
    padding: 10px;
    background: var(--secondary-color);
    color: white;
    font-size: 16px;
}

.btn-theme-o {
    border-radius: 100px;
    background: white;
    border: 2px solid var(--secondary-color);
    min-width: 150px;
    padding: 10px;
    color: var(--secondary-color);
    font-size: 16px;
}

a,
a:hover,
a:visited,
a:active {
    text-decoration: none;
    outline: none;
}

::-webkit-scrollbar {
    /* width: 5px; */
    width: 0px;
}


::-webkit-scrollbar-track {
    background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 100px;
}

::-webkit-scrollbar-thumb:hover {
    background: #555;
}

button:focus {
    outline: none;
}

input {
    width: auto;
}

input[type=number] {
    -moz-appearance: textfield;
}


.RichTextEditor__editor___1QqIU .public-DraftEditor-content {
    overflow: auto;
    height: calc(100vh - 250px);

}

.draggable {
    cursor: move;
    cursor: -webkit-grab;
    cursor: -moz-grab;
    cursor: grab;
}

.draggable:active {
    cursor: -webkit-grabbing;
    cursor: -moz-grabbing;
    cursor: grabbing;
}