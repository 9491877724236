.add_child_modal_container{
    display: flex;
    flex-direction: column;
    padding: 2rem;
    gap: 1rem;
}

.add_child_modal_container .dropdown-container{
  border: 2px solid #004ea3 !important;
  border-radius: 10px !important;
}