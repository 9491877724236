.update_grade_section_container{
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    /* min-height: 65vh; */
    justify-content: space-between;
}

.update_grade_section_body{
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.update_grade_section_title{
    font-size: 1.5rem;
    font-weight: 600;
}

.update_grade_section_footer{
    width: 100%;
    height: 100%;
    min-height: 4rem;
}